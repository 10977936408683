@import url("https://fonts.googleapis.com/css2?family=Space+Mono&display=swap");

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Space Mono", monospace;
  color: #fff;
}

.muted {
  color: #6c6c6c;
}

.main {
  background-image: url("../images/wolfmirror.jpg");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 10px #000;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
  }
}

h1 {
  text-transform: lowercase;
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 1;
  font-size: 1.5em;

  @media (min-width: 1040px) {
    font-size: 2.125em;
  }
}
