@import "https://fonts.googleapis.com/css2?family=Space+Mono&display=swap";
html, body {
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  font-family: Space Mono, monospace;
}

.muted {
  color: #6c6c6c;
}

.main {
  text-shadow: 0 0 10px #000;
  background-image: url("wolfmirror.dc81678b.jpg");
  background-position: center;
  background-size: cover;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
}

.main:after {
  content: "";
  background: rgba(0, 0, 0, .05);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

h1 {
  text-transform: lowercase;
  z-index: 1;
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  position: relative;
}

@media (min-width: 1040px) {
  h1 {
    font-size: 2.125em;
  }
}

/*# sourceMappingURL=index.644db4eb.css.map */
